interface ChatResponse {
  choices: Array<{
    message: {
      content: string;
    };
  }>;
}

export const sendChatMessage = async (message: string): Promise<ChatResponse> => {
  const response = await fetch('/api/chat', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message })
  });

  if (!response.ok) {
    throw new Error('Failed to send message');
  }

  return response.json();
}; 
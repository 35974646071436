import { Users, LineChart, ChevronRight, ArrowRight, GraduationCap } from 'lucide-react';
import { useState } from 'react';
import beemindLogo from './assets/beemind-logo.svg';
import beemindLogoWhite from './assets/beemind-logo-white.svg';
import { useNavigate, Routes, Route } from 'react-router-dom';
import FinGenieBeta from './pages/FinGenieBeta';
import aiMindVisualization from './assets/ai-mind-visualization.jpg';
import dashboardMain from './assets/dashboard-main.jpg';
import Chatbot from './components/Chatbot';

// Add type definition
type Course = {
  title: string;
  duration: string;
  level: string;
  price: string;
  overview: string;
  topics: string[];
  batch: string;
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />} />
      <Route path="/fingenie-beta" element={<FinGenieBeta />} />
    </Routes>
  );
}

function MainLayout() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [isEnrollModalOpen, setIsEnrollModalOpen] = useState(false);
  const [enrollEmail, setEnrollEmail] = useState('');
  const [enrollCourse, setEnrollCourse] = useState<Course | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const courses = [
    {
      title: 'AI for Managers',
      duration: '3 Days',
      level: 'Executive',
      price: '$3,000',
      overview: 'Designed for business leaders, this workshop covers AI concepts, strategic implementation, and decision-making with AI-powered insights. Ideal for executives, product managers, and decision-makers.',
      topics: [
        'AI concepts and business applications',
        'Data-driven decision-making',
        'AI-driven financial intelligence',
        'Ethical AI & risk mitigation',
        'Real-world case studies'
      ],
      batch: 'up to 20 participants'
    },
    {
      title: 'Generative AI for Developers',
      duration: '5 Days',
      level: 'Advanced',
      price: '$7,500',
      overview: 'A hands-on program for developers to build, fine-tune, and deploy generative AI models for real-world applications. Covers NLP, LLMs, and AI-driven automation.',
      topics: [
        'Fundamentals of Generative AI & LLMs',
        'Prompt engineering & fine-tuning models',
        'Building AI-powered applications',
        'Deploying AI models at scale',
        'Ethics & biases in AI'
      ],
      batch: 'up to 20 participants'
    },
    {
      title: 'Data Engineering to AI',
      duration: '6 Days',
      level: 'Intermediate',
      price: '$15,000',
      overview: 'A comprehensive course bridging the gap between data engineering and AI implementation. Designed for professionals looking to transition into AI-powered analytics and model deployment.',
      topics: [
        'Data pipelines & engineering fundamentals',
        'Data preprocessing for AI',
        'ML model training & optimization',
        'Deploying AI models in production',
        'AI-powered financial analytics & automation'
      ],
      batch: 'up to 20 participants'
    }
  ];

  const handleEnrollSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('https://formsubmit.co/ajax/info@beemind.ai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: enrollEmail,
          course: enrollCourse?.title,
          message: `New enrollment request for ${enrollCourse?.title} course from ${enrollEmail}`,
        }),
      });

      if (response.ok) {
        alert('Thank you for your interest! We will contact you shortly.');
        setEnrollEmail('');
        setIsEnrollModalOpen(false);
        setEnrollCourse(null);
      }
    } catch (error) {
      alert('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Header/Navigation */}
      <nav className="bg-white shadow-sm sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center">
              <img 
                src={beemindLogo}
                alt="BeeMind Logo" 
                className="h-14 w-auto"
              />
            </div>
            <div className="hidden md:flex items-center space-x-8">
              <a 
                href="#services" 
                className="text-gray-800 hover:text-orange-600 font-medium text-base transition-colors duration-300 relative group"
              >
                Services
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-orange-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
              <a 
                href="#fingenie" 
                className="text-gray-800 hover:text-orange-600 font-medium text-base transition-colors duration-300 relative group"
              >
                Fin Genie
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-orange-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
              <a 
                href="#bootcamps" 
                className="text-gray-800 hover:text-orange-600 font-medium text-base transition-colors duration-300 relative group"
              >
                AI Bootcamps
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-orange-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
              <button 
                onClick={() => {
                  document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
                }}
                className="bg-orange-600 text-white px-6 py-2.5 rounded-lg hover:bg-orange-700 transition-colors duration-300 font-medium shadow-md hover:shadow-lg"
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-br from-purple-700 via-purple-800 to-indigo-900">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
                  <span className="block bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 leading-tight">
                    Revolutionizing the Future
                  </span>
                  <span className="block bg-clip-text text-transparent bg-gradient-to-r from-orange-600 via-orange-500 to-orange-400 mt-2 leading-tight pb-2">
                    of Finance with AI
                  </span>
                </h1>
                <p className="mt-6 text-base text-white sm:mt-8 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-8 md:text-xl lg:mx-0 leading-relaxed">
                  At Beemind AI, we specialize in AI-driven financial intelligence, developing cutting-edge models for stock valuation, market trend analysis, and investment insights.
                </p>
                
                <div className={`transition-all duration-500 ease-in-out overflow-hidden ${isExpanded ? 'max-h-96' : 'max-h-0'}`}>
                  <p className="mt-6 text-base text-white sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl lg:mx-0 leading-relaxed">
                    Our advanced AI solutions help investors, analysts, and businesses make data-backed decisions, track commitments, and uncover deep market opportunities with precision.
                  </p>
                  <p className="mt-6 text-base text-white sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl lg:mx-0 leading-relaxed">
                    Beyond solutions, our AI consulting framework empowers businesses to design, implement, and scale domain-specific AI systems. Whether you're looking to integrate AI-driven analytics, automate financial workflows, or build custom AI models, our expertise ensures a seamless transformation tailored to your industry needs.
                  </p>
                </div>

                <div className="mt-8 sm:mt-10">
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="text-indigo-200 hover:text-white flex items-center gap-2 transition-colors duration-300 text-lg font-medium"
                  >
                    {isExpanded ? 'Read Less' : 'Read More'}
                    <ChevronRight className={`h-5 w-5 transform transition-transform duration-300 ${isExpanded ? 'rotate-90' : ''}`} />
                  </button>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={aiMindVisualization}
            alt="AI mind visualization with holographic interface and data analytics"
          />
        </div>
      </div>

      {/* Services Section */}
      <section id="services" className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Our Services</h2>
            <p className="mt-4 text-xl text-gray-600">Comprehensive AI solutions for your business needs</p>
          </div>

          <div className="mt-20 grid grid-cols-1 gap-8 md:grid-cols-3">
            {/* AI Consulting Card */}
            <div className="group bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-500 ease-in-out overflow-hidden">
              <div className="p-8 h-full">
                <div className="flex items-center justify-center w-14 h-14 bg-gradient-to-br from-orange-100 to-orange-50 rounded-xl">
                  <Users className="h-7 w-7 text-orange-600" />
                </div>
                <h3 className="mt-6 text-2xl font-semibold text-gray-900">AI Consulting</h3>
                <div className="relative mt-4">
                  <div className="transition-all duration-500 ease-in-out transform origin-top">
                    <p className="text-gray-600 leading-relaxed">
                      Expert guidance on integrating AI into your business seamlessly. From strategy development to deployment, we help organizations build domain-specific AI models that enhance decision-making, optimize operations, and unlock new growth opportunities.
                    </p>
                    <div className={`overflow-hidden transition-all duration-500 ease-in-out ${
                      'max-h-0 group-hover:max-h-[200px]'
                    }`}>
                      <p className="mt-4 text-gray-600 leading-relaxed">
                        Our tailored approach ensures smooth AI adoption, enabling businesses to leverage data-driven insights and stay ahead in an AI-powered world.
                      </p>
                    </div>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white via-white to-transparent group-hover:opacity-0 transition-opacity duration-500"></div>
                </div>
                <div className="mt-6 flex items-center text-orange-600 group-hover:translate-y-1 transition-transform duration-500">
                  <span className="text-sm font-medium">
                    <span className="group-hover:hidden">Learn More</span>
                    <span className="hidden group-hover:inline">Show Less</span>
                  </span>
                  <ChevronRight className="h-4 w-4 ml-1 transform group-hover:rotate-90 transition-transform duration-500" />
                </div>
              </div>
            </div>

            {/* FinGenie Card */}
            <div className="group bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-500 ease-in-out overflow-hidden">
              <div className="p-8 h-full">
                <div className="flex items-center justify-center w-14 h-14 bg-gradient-to-br from-orange-100 to-orange-50 rounded-xl">
                  <LineChart className="h-7 w-7 text-orange-600" />
                </div>
                <h3 className="mt-6 text-2xl font-semibold text-gray-900">Data Analytics with FinGenie</h3>
                <div className="relative mt-4">
                  <div className="transition-all duration-500 ease-in-out transform origin-top">
                    <p className="text-gray-600 leading-relaxed">
                      Unlock the power of financial data with FinGenie, your virtual financial analyst. FinGenie streamlines financial data analysis, tracks market trends, and evaluates stock valuations with AI-driven precision.
                    </p>
                    <div className={`overflow-hidden transition-all duration-500 ease-in-out ${
                      'max-h-0 group-hover:max-h-[200px]'
                    }`}>
                      <p className="mt-4 text-gray-600 leading-relaxed">
                        Whether you're an investor, analyst, or enterprise, FinGenie empowers you with actionable insights to make informed decisions.
                      </p>
                    </div>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white via-white to-transparent group-hover:opacity-0 transition-opacity duration-500"></div>
                </div>
                <div className="mt-6 flex items-center text-orange-600 group-hover:translate-y-1 transition-transform duration-500">
                  <span className="text-sm font-medium">
                    <span className="group-hover:hidden">Learn More</span>
                    <span className="hidden group-hover:inline">Show Less</span>
                  </span>
                  <ChevronRight className="h-4 w-4 ml-1 transform group-hover:rotate-90 transition-transform duration-500" />
                </div>
              </div>
            </div>

            {/* AI Training Card */}
            <div className="group bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-500 ease-in-out overflow-hidden">
              <div className="p-8 h-full">
                <div className="flex items-center justify-center w-14 h-14 bg-gradient-to-br from-orange-100 to-orange-50 rounded-xl">
                  <GraduationCap className="h-7 w-7 text-orange-600" />
                </div>
                <h3 className="mt-6 text-2xl font-semibold text-gray-900">AI Training & Bootcamps</h3>
                <div className="relative mt-4">
                  <div className="transition-all duration-500 ease-in-out transform origin-top">
                    <p className="text-gray-600 leading-relaxed">
                      Stay ahead in the AI revolution with our expert-led AI training programs and bootcamps. Designed for professionals and organizations, our courses cover AI strategy, implementation, and domain-specific applications.
                    </p>
                    <div className={`overflow-hidden transition-all duration-500 ease-in-out ${
                      'max-h-0 group-hover:max-h-[200px]'
                    }`}>
                      <p className="mt-4 text-gray-600 leading-relaxed">
                        Equipping you with the skills to harness AI for business growth and innovation.
                      </p>
                    </div>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white via-white to-transparent group-hover:opacity-0 transition-opacity duration-500"></div>
                </div>
                <div className="mt-6 flex items-center text-orange-600 group-hover:translate-y-1 transition-transform duration-500">
                  <span className="text-sm font-medium">
                    <span className="group-hover:hidden">Learn More</span>
                    <span className="hidden group-hover:inline">Show Less</span>
                  </span>
                  <ChevronRight className="h-4 w-4 ml-1 transform group-hover:rotate-90 transition-transform duration-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Fin Genie Section */}
      <section id="fingenie" className="py-20 bg-gradient-to-br from-gray-50 to-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="lg:w-1/2">
              <h2 className="text-4xl font-extrabold text-gray-900">
                Meet Fin Genie
                <span className="block text-orange-600 mt-2">Your AI Stock Analysis Partner</span>
              </h2>
              <p className="mt-6 text-xl text-gray-600 leading-relaxed">
                Advanced stock analysis powered by artificial intelligence to help you make informed investment decisions.
              </p>
              <div className="mt-8">
                <ul className="space-y-4">
                  {[
                    'Real-time market analysis',
                    'Predictive analytics',
                    'Portfolio optimization',
                    'Risk assessment'
                  ].map((feature) => (
                    <li key={feature} className="flex items-center text-gray-700">
                      <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-orange-100">
                        <ChevronRight className="h-4 w-4 text-orange-600" />
                      </div>
                      <span className="ml-3">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-8">
                <button 
                  onClick={() => navigate('/fingenie-beta')}
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-orange-600 hover:bg-orange-700 transition-colors duration-300 shadow-md hover:shadow-lg"
                >
                  Try Fin Genie <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="mt-10 lg:mt-0 lg:w-1/2 relative">
              <div className="relative rounded-lg overflow-hidden shadow-2xl">
                <div className="absolute inset-0 bg-gradient-to-br from-orange-500/10 to-purple-500/10 z-10"></div>
                <img
                  src={dashboardMain}
                  alt="FinGenie Dashboard"
                  className="w-full rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* AI Bootcamps Section */}
      <section id="bootcamps" className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">AI Bootcamps</h2>
            <p className="mt-4 text-xl text-gray-600">Transform your career with expert-led AI training</p>
          </div>

          <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {courses.map((course) => (
              <div key={course.title} className="bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300">
                <div className="p-8">
                  <div className="flex items-center justify-center w-14 h-14 bg-gradient-to-br from-orange-100 to-orange-50 rounded-xl">
                    <GraduationCap className="h-7 w-7 text-orange-600" />
                  </div>
                  <h3 className="mt-6 text-2xl font-semibold text-gray-900">{course.title}</h3>
                  <div className="mt-4 flex items-center justify-between text-sm">
                    <span className="px-3 py-1 bg-orange-100 text-orange-800 rounded-full">{course.level}</span>
                    <span className="text-gray-600">{course.duration}</span>
                  </div>
                  <button
                    onClick={() => setSelectedCourse(course)}
                    className="mt-6 w-full bg-orange-600 text-white px-4 py-2.5 rounded-lg hover:bg-orange-700 transition-colors duration-300 flex items-center justify-center gap-2"
                  >
                    Learn More
                    <ChevronRight className="h-4 w-4" />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Course Details Modal */}
          {selectedCourse && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
              <div className="bg-white rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <div className="p-6 sm:p-8">
                  <div className="flex justify-between items-start">
                    <h3 className="text-2xl font-bold text-gray-900">{selectedCourse.title}</h3>
                    <button
                      onClick={() => setSelectedCourse(null)}
                      className="text-gray-400 hover:text-gray-500 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                    >
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <div className="mt-6 space-y-6">
                    <div>
                      <h4 className="font-medium text-gray-900">Overview</h4>
                      <p className="mt-2 text-gray-600">{selectedCourse.overview}</p>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900">Topics Covered</h4>
                      <ul className="mt-2 space-y-2">
                        {selectedCourse.topics.map((topic) => (
                          <li key={topic} className="flex items-start">
                            <ChevronRight className="h-5 w-5 text-orange-600 flex-shrink-0 mt-0.5" />
                            <span className="ml-2 text-gray-600">{topic}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="flex items-center justify-between pt-6 border-t">
                      <div>
                        <p className="text-gray-600">Institutional Pricing</p>
                        <p className="text-2xl font-bold text-gray-900">{selectedCourse.price}</p>
                        <p className="text-sm text-gray-600">{selectedCourse.batch}</p>
                      </div>
                      <button 
                        onClick={() => {
                          setEnrollCourse(selectedCourse);
                          setSelectedCourse(null);
                          setIsEnrollModalOpen(true);
                        }}
                        className="bg-orange-600 text-white px-6 py-2.5 rounded-lg hover:bg-orange-700 transition-colors duration-300"
                      >
                        Enroll Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Enrollment Modal */}
          {isEnrollModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
              <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-semibold text-gray-900">Enroll in {enrollCourse?.title}</h3>
                  <button
                    onClick={() => {
                      setIsEnrollModalOpen(false);
                      setEnrollEmail('');
                    }}
                    className="text-gray-400 hover:text-gray-500 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                  >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <form onSubmit={handleEnrollSubmit}>
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      required
                      value={enrollEmail}
                      onChange={(e) => setEnrollEmail(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Enter your email"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-orange-600 text-white px-4 py-2 rounded-md hover:bg-orange-700 transition-colors duration-300 disabled:opacity-50 flex items-center justify-center"
                  >
                    {isSubmitting ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Submitting...
                      </>
                    ) : (
                      'Submit Enrollment'
                    )}
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <div className="flex items-center">
                <img 
                  src={beemindLogoWhite}
                  alt="Beemind AI Logo" 
                  className="h-8 w-auto"
                />
              </div>
              <p className="mt-4 text-gray-400">
                Transforming businesses through artificial intelligence.
              </p>
            </div>
            <div>
              <h3 className="text-white font-semibold">Quick Links</h3>
              <ul className="mt-4 space-y-2">
                <li><a href="#services" className="text-gray-400 hover:text-white">Services</a></li>
                <li><a href="#fingenie" className="text-gray-400 hover:text-white">Fin Genie</a></li>
                <li><a href="#bootcamps" className="text-gray-400 hover:text-white">AI Bootcamps</a></li>
              </ul>
            </div>
            <div>
              <h3 id="contact" className="text-white font-semibold">Contact Us</h3>
              <ul className="mt-4 space-y-2">
                <li className="text-gray-400">
                  <span className="block font-medium">Address</span>
                  Gokarna, KA, India
                </li>
                <li className="text-gray-400">
                  <span className="block font-medium">Email</span>
                  <a href="mailto:info@beemind.ai" className="hover:text-white transition-colors duration-300">
                    info@beemind.ai
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-800 pt-8">
            <p className="text-gray-400 text-center">© 2024 Beemind AI. All rights reserved.</p>
          </div>
        </div>
      </footer>
      <Chatbot />
    </div>
  );
}

export default App;
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function FinGenieBeta() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNotifySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('https://formsubmit.co/ajax/info@beemind.ai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          subject: 'FinGenie Beta Notification Request',
          message: `New notification request for FinGenie Beta from ${email}`,
        }),
      });

      if (response.ok) {
        alert('Thanks for your interest! We\'ll notify you when FinGenie launches.');
        setEmail('');
      }
    } catch (error) {
      alert('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-4">
      <div className="max-w-4xl w-full">
        <button 
          onClick={() => navigate('/')}
          className="mb-8 flex items-center text-gray-600 hover:text-orange-600 transition-colors duration-300 p-2 hover:bg-white/50 rounded-lg"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Home
        </button>
        
        <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
          <div className="p-8 text-center relative">
            <button
              onClick={() => navigate('/')}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              FinGenie Beta
              <span className="block text-orange-600 text-2xl mt-2">Coming Soon</span>
            </h1>
            <p className="text-gray-600 text-lg mb-8">
              We're working hard to bring you the next generation of AI-powered financial analysis.
            </p>
            
            {/* Animated Coming Soon Visualization */}
            <div className="relative w-full max-w-2xl mx-auto aspect-video bg-gradient-to-b from-orange-50 to-gray-50 rounded-lg overflow-hidden">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="animate-pulse text-orange-600 text-9xl opacity-10">β</div>
              </div>
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="space-y-4 text-center">
                  <div className="animate-pulse inline-block px-4 py-2 bg-orange-600 text-white rounded-full text-sm">
                    Beta Version
                  </div>
                  <div className="block text-gray-800 font-semibold">
                    Launching Soon
                  </div>
                </div>
              </div>
            </div>
            
            {/* Email Notification Form */}
            <div className="mt-12 max-w-md mx-auto">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Get notified when we launch
              </h3>
              <form className="flex gap-2" onSubmit={handleNotifySubmit}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-6 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-colors duration-300 disabled:opacity-50 flex items-center"
                >
                  {isSubmitting ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Submitting...
                    </>
                  ) : (
                    'Notify Me'
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 